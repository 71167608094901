import { AIFunctionAnimationStatus } from '@/context/messengerContext';
import cn from 'classnames';
import React, { FC } from 'react';

type AIFunctionAnimationProps = {
  className?: string;
  status: AIFunctionAnimationStatus;
};
const AIFunctionAnimation: FC<AIFunctionAnimationProps> = ({
  className,
  status,
}) => {
  if (status === 'idle') return null;

  const text =
    status === 'run' ? 'Starting action...' : 'Processing AI Response...';

  return (
    <div
      className={cn('flex flex-col items-center justify-center text-center', {
        className,
      })}
    >
      <div className="logo-animation" />
      <p className=" text-sm mb-1.5">{text}</p>
      <p className=" text-xs text-[#777]">it can take a minute</p>
    </div>
  );
};

export default AIFunctionAnimation;
