type EventType = 'lc.hideMessenger' | 'lc.showMessenger';

interface EventBase<
  TEventType extends EventType,
  TListener extends (
    data: Extract<InstanceType<typeof Event>, CustomEvent<TData>>,
  ) => void,
  TData extends object = {},
> {
  type: TEventType;
  listener: TListener;
  data: TData;
}

type Events =
  | EventBase<'lc.hideMessenger', () => void>
  | EventBase<'lc.showMessenger', () => void>;

const subscribe = <TEventType extends EventType>(
  eventType: TEventType,
  listener: Extract<Events, { type: TEventType }>['listener'],
) => {
  window &&
    window.addEventListener(eventType, {
      handleEvent: listener,
    });
};

const unsubscribe = <TEventType extends EventType>(
  eventType: TEventType,
  listener: Extract<Events, { type: TEventType }>['listener'],
) => {
  window &&
    window.removeEventListener(eventType, {
      handleEvent: listener,
    });
};

const publish = <TEventType extends EventType>(
  eventType: TEventType,
  data?: Extract<Events, { type: TEventType }>['data'],
) => {
  const event = new CustomEvent(eventType, {
    detail: data,
  });
  window && window.dispatchEvent(event);
};

export { publish, subscribe, unsubscribe };
