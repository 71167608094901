import React, { useEffect, useState } from 'react';

import LoadingDots from '@/components/loading/loading-dots.components';
import useTranslationStore from '@/store/translation';

const MessageSkeleton = () => {
  const [dotCount, setDotCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setDotCount((prevDotCount) => (prevDotCount + 1) % 4);
    }, 400);

    return () => clearInterval(interval);
  }, []);

  const { getValue } = useTranslationStore();

  return (
    <div className="flex items-center gap-x-2.5">
      <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100">
        <LoadingDots size={20} />
      </div>

      <div className="bg-gradient-to-r from-gray-950 to-gray-300 bg-clip-text text-sm text-transparent">
        {getValue('aiIsWriting')}
        {'.'.repeat(dotCount)}
      </div>
    </div>
  );
};

export default MessageSkeleton;
