const mobileRegex =
  /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
const checkIsMobile = () => {
  try {
    return mobileRegex?.test(navigator.userAgent);
  } catch {
    return false;
  }
};

export { checkIsMobile };
