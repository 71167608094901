'use client';

import useTranslationStore from '@/store/translation';
import { FC } from 'react';

type AgentJoinedProps = {
  agentName: string;
};

const AgentJoined: FC<AgentJoinedProps> = ({ agentName }) => {
  const { getValue } = useTranslationStore();

  return (
    <div className="grid grid-cols-[1fr_max-content_1fr] items-center py-3">
      <hr className="border-0 border-b border-gray-300 m-0 p-0 mr-[6px]" />
      <span className="text-xs leading-4 font-normal w-max">
        {agentName} {getValue('joinText')}
      </span>
      <hr className="border-0 border-b border-gray-300 m-0 p-0 ml-[6px]" />
    </div>
  );
};

export default AgentJoined;
