import { FC } from 'react';
import cn from 'classnames';
import { SvgProps } from './types';

const ChatIcon: FC<SvgProps> = ({
  className,
  width = '17',
  height = '16',
  viewBox = '0 0 17 16',
}) => {
  return (
    <svg
      className={cn(className)}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 13.3333C11.8137 13.3333 14.5 10.9455 14.5 7.99999C14.5 5.05447 11.8137 2.66666 8.5 2.66666C5.18629 2.66666 2.5 5.05447 2.5 7.99999C2.5 9.04951 2.84104 10.0282 3.42999 10.8533L2.5 13.3333L5.66311 12.7007C6.50781 13.1043 7.47382 13.3333 8.5 13.3333Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ChatIcon;
